import React from "react";
const CorporateForm = () => {
  return (
    <section className="col-lg-12">
      <div className="container">
      <section className="erp_call_action_area">
            <div className="container">
                <div className="erp_action_content text-center">
                    <img src={require("../../../assets/image/rocket.png")} alt=""/>
                    <h3>Experience a smarter way to become Saathi</h3>
                    <p>With All-in-one<span>Corporate Saathi</span>App</p>
                    <a href="/#" className="er_btn er_btn_two">Get Corportae Form</a>
                </div>
            </div>
        </section>
      </div>
    </section>
  );
};
export default CorporateForm;
