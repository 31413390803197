import React, { useState,useRef, useEffect } from 'react'
import { Col,Row, Card,CardBody} from "reactstrap"
import { Button } from "@mui/material";
import { Label } from "reactstrap"
import emailjs from '@emailjs/browser';
import { Spinner } from "react-bootstrap";
import unnamed from "./kisan.png"
import Swal from 'sweetalert2'
const DeleteApplicationKisan = () => {
    const form = useRef();
    const[loading,setLoading]=useState(false)
    const sendEmail = (e) => {
        e.preventDefault(); 
        setLoading(true)
        emailjs.sendForm('service_hiayu8t', 'template_dfq9x0v', form.current, 'A5luKHIpn88Y2372P')
          .then((result) => {
              // show the user a success message
              console.log(result.text)
              setLoading(false)
              
              Swal.fire({
                icon: "success",
                title: `Request has been submitted Successfully`,
                showConfirmButton: true,
               
              });
              form.current.reset();

          }, (error) => {
              // show the user an error
              console.log(error.text)
              setLoading(false)
          });
      };


    return (
        <div>
        <nav className="navbar navbar-expand-lg navbar-light  row d-flex justify-content-around p-lg-3 bg-light ">
        <div className='mt-2'>
        <b className="navbar-brand" style={{fontWeight:"600"}} >
          Agrani Kisan
          <br />
          <p className=' fontSizeMobile m-0' style={{color:"#d98d13",fontWeight:"600"}}>LeadsConnect Services Pvt Ltd.</p>
        </b>
        </div>
        <div  id="navbarText">
          <img src={unnamed} width="140px"  alt="Saathi Logo" style={{objectFit: "contain", height:"70px"}} />
      </div>
      </nav>
        <div className="container d-flex justify-content-center align-items-center  mt-lg-5 mt-5">
            <Card style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} className='mt-lg-5 mt-2' >
                <CardBody style={{padding:"1.5rem"}} className='mt-2'>
                <h5 className='text-gray'>Request for Account Deletion</h5>
                <form ref={form} onSubmit={sendEmail}>
                    <Row className="text-gray mt-4">
                    <Col lg="1"></Col>
                            <div className="col-lg-3 form-group mb-2 text_box mt-1">
                            <Label className="f_p text_c f_400">
                                Your Name <small className="text-danger">*</small>
                            </Label>
                            <input
                                name="user_name"
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                required
                                style={{ height: '47px', fontSize: '14px', color: '#333' }}
                                />
                            </div>
                        <div className="col-lg-3 form-group mb-2 text_box mt-1" >
                        <Label className="f_p text_c f_400">
                             Mobile Number / Login Id <small className="text-danger">*</small>
                        </Label>
                        <div className='d-flex'>
                        <div style={{width:"25%"}}>
                        <input
                        name="user_zipcode"
                        type="text"
                        className="form-control"
                        maxLength="3"
                        placeholder="+91"
                        defaultValue="+91"
                        disabled
                        
                        style={{ height: '47px',width:"100%", fontSize: '14px', color: '#333',background:"#FAFAFA",boxShadow: "0px 2px 4px 0px rgba(12, 0, 46, 0.04)",border: "1px solid #e7e7e7" }}
                      />   
                      </div>  
                      <div style={{width:"75%"}}>
                      <input
                            name="user_mobile"
                            type="tel"
                            className="form-control"
                            maxLength="10"
                            placeholder="Enter Number"
                            required
                            style={{ height: '47px', fontSize: '14px', color: '#333',background:"#FAFAFA",boxShadow: "0px 2px 4px 0px rgba(12, 0, 46, 0.04)",border: "1px solid #e7e7e7",borderLeft: "1px solid transparent" }}
                          />
                          </div>
                          </div>
                    </div>
                    <div className="col-lg-3 form-group mb-2 text_box mt-1">
                    <Label className="f_p text_c f_400">
                        Alternate Mobile Number 
                    </Label>


                    <div className='d-flex'>
                    <div style={{width:"25%"}}>
                    <input
                    name="userAlt_zipcode"
                    type="text"
                    className="form-control"
                    maxLength="3"
                    placeholder="+91"
                    defaultValue="+91"
                    disabled
                    
                    style={{ height: '47px',width:"100%", fontSize: '14px', color: '#333',background:"#FAFAFA",boxShadow: "0px 2px 4px 0px rgba(12, 0, 46, 0.04)",border: "1px solid #e7e7e7" }}
                  />   
                  </div>  
                  <div style={{width:"75%"}}>
                  <input
                        name="alternate_mobile"
                        type="tel"
                        className="form-control"
                        maxLength="10"
                        placeholder="Enter Number"
                        
                        style={{ height: '47px', fontSize: '14px', color: '#333',background:"#FAFAFA",boxShadow: "0px 2px 4px 0px rgba(12, 0, 46, 0.04)",border: "1px solid #e7e7e7",borderLeft: "1px solid transparent" }}
                      />
                      </div>
                      </div>























                 
                </div>
               
                </Row>
                <Row className="text-gray mt-4">
                <Col lg="1"></Col>
                    <div className="col-lg-3 form-group mb-2 text_box mt-1">
                            <Label className="f_p text_c f_400">
                                Your Email Id 
                            </Label>
                            <input
                                name="user_email"
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                
                                style={{ height: '47px', fontSize: '14px', color: '#333' }}
                            />
                        </div>
                        <div className="col-lg-6 form-group mb-2 text_box mt-1">
                            <Label className="f_p text_c f_400">
                                Reason For Deleting Account <small className="text-danger">*</small>
                            </Label>
                            <input
                                name="remarks"
                                type="text"
                                className="form-control"
                                placeholder="Enter Remarks"
                                required
                                style={{ height: '47px', fontSize: '14px', color: '#333' }}
                            />
                        </div>
                        
                    </Row>
                    <div className=""> <p className="text-gray text-italic text-bottom  mt-4 mb-0"><strong>Note : </strong>
                    We will call you to verify your account, Once the account is verified the account and data will be permanently deleted.
                    <br/>
                  <strong className='mt-3'> Account deletion TAT : 24 hours</strong>
                   </p></div>
                    <Button
                    variant="contained"
                    color="success"
                    className={`btn btn-success me-2 px-3 custom-btn-fs mt-4 float-right`}
                    type="submit">
                    Submit &nbsp;{loading?<Spinner animation="border" size="sm" />:""}
                 </Button>
                    </form>
                  
                </CardBody>
            </Card>
        </div>
        </div>
    )
}

export default DeleteApplicationKisan
